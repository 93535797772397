<template>
  <div>
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Dashboard</h1>
    </div>

    <div class="row">
      <div class="col-xl-12 col-md-6 mb-4">
        <div class="card border-left-success shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-success text-uppercase mb-1"
                >
                  <h2 class="h1 font-weight-bold">
                    Customer Care Total Handling 2020
                  </h2>
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800"></div>
              </div>
              <div class="col-auto">
                <h1 class="font-weight-bolder text-danger">
                  {{ channel.whatsapp }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-success shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-success text-uppercase mb-1"
                >
                  Whatsapp
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ channel.whatsapp }}
                </div>
              </div>
              <div class="col-auto">
                <i class="fab fa-whatsapp fa-3x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-secondary shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-success text-uppercase mb-1"
                >
                  Email
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ channel.email }}
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-envelope fa-3x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-success shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-info text-uppercase mb-1"
                >
                  Voice
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ channel.voice }}
                </div>
              </div>
              <div class="col-auto">
                <i class="fas fa-phone-square fa-3x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-3 col-md-6 mb-4">
        <div class="card border-left-blue shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-blue text-uppercase mb-1"
                >
                  Telegram
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{ channel.telegram }}
                </div>
              </div>
              <div class="col-auto">
                <i class="fab fa-telegram-plane fa-3x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4 col-lg-5">
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">
              Summary Contact (By Channel)
            </h6>
          </div>
          <div class="card-body">
            <div class="chart__container" v-if="chart.channelData.loaded">
              <pie-chart
                :chartdata="chart.channelData"
                :options="chart.chartOptions"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-lg-5">
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">
              Summary Ticket (By Status)
            </h6>
          </div>
          <div class="card-body">
            <div class="chart__container" v-if="chart.ticketStatusData.loaded">
              <pie-chart
                :chartdata="chart.ticketStatusData"
                :options="chart.chartOptions"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-lg-5">
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">
              All Ticket Category Summary
            </h6>
          </div>
          <div class="card-body">
            <div class="chart__container" v-if="chart.categoryData.loaded">
              <pie-chart
                :chartdata="chart.categoryData"
                :options="chart.chartOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "@/components/chart/PieChart.vue";

export default {
  components: {
    PieChart,
  },
  data() {
    return {
      channel: {
        whatsapp: 0,
        email: 0,
        telegram: 0,
        voice: 0,
      },
      chart: {
        channelData: {
          loaded: false,
          labels: [],
          datasets: [
            {
              borderWidth: 1,
              borderColor: [
                "rgba(255,99,132,1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(75, 192, 192, 1)",
              ],
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(75, 192, 192, 0.2)",
              ],
              data: [],
            },
          ],
        },
        ticketStatusData: {
          loaded: false,
          labels: [],
          datasets: [
            {
              borderWidth: 1,
              borderColor: [
                "rgba(255,99,132,1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(75, 192, 192, 1)",
              ],
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(75, 192, 192, 0.2)",
              ],
              data: [],
            },
          ],
        },
        categoryData: {
          loaded: false,
          labels: [],
          datasets: [
            {
              borderWidth: 1,
              borderColor: [
                "rgba(255,99,132,1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(75, 192, 192, 1)",
              ],
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(75, 192, 192, 0.2)",
              ],
              data: [],
            },
          ],
        },
        chartOptions: {
          legend: {
            display: true,
            position: "right",
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      },
    };
  },
  created() {
    this.getChannelData();
    this.getTotalTicket();
    this.getTotalCategory();
  },
  methods: {
    getChannelData() {
      var config = {
        method: "post",
        url:
          "http://localhost:9001/api/analytic/getTotalByChannel?Month=10&Year=2020",
        headers: {
          Authorization:
            "Basic dGVsa29tOmRhMWMyNWQ4LTM3YzgtNDFiMS1hZmUyLTQyZGQ0ODI1YmZlYQ==",
        },
      };
      this.axios(config)
        .then((response) => {
          if (response.data.content.length > 0) {
            let datas = response.data.content;

            let labels = [];
            let labelTotal = [];

            datas.forEach((data) => {
              labels.push(data.channel);
              labelTotal.push(data.total);

              if (data.channel === "Whatsapp") {
                this.channel.whatsapp = data.total;
              }
              if (data.channel === "Voice") {
                this.channel.voice = data.total;
              }
              if (data.channel === "Telegram") {
                this.channel.telegram = data.total;
              }
              if (data.channel === "Email") {
                this.channel.email = data.total;
              }
            });
            this.chart.channelData.labels = labels;
            this.chart.channelData.datasets[0].data = labelTotal;
            this.chart.channelData.loaded = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getTotalTicket() {
      var config = {
        method: "post",
        url:
          "http://localhost:9001/api/analytic/getTotalTicketByStatus?Month=10&Year=2020",
        headers: {
          Authorization:
            "Basic dGVsa29tOmRhMWMyNWQ4LTM3YzgtNDFiMS1hZmUyLTQyZGQ0ODI1YmZlYQ==",
        },
      };
      this.axios(config)
        .then((response) => {
          if (response.data.content.length > 0) {
            let datas = response.data.content;

            let labels = [];
            let labelTotal = [];

            datas.forEach((data) => {
              labels.push(data.ticket_status);
              labelTotal.push(data.total);
            });
            this.chart.ticketStatusData.labels = labels;
            this.chart.ticketStatusData.datasets[0].data = labelTotal;
            this.chart.ticketStatusData.loaded = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getTotalCategory() {
      var config = {
        method: "post",
        url:
          "http://localhost:9001/api/analytic/getTotalByCategory?Month=10&Year=2020",
        headers: {
          Authorization:
            "Basic dGVsa29tOmRhMWMyNWQ4LTM3YzgtNDFiMS1hZmUyLTQyZGQ0ODI1YmZlYQ==",
        },
      };
      this.axios(config)
        .then((response) => {
          if (response.data.content.length > 0) {
            let datas = response.data.content;

            let labels = [];
            let labelTotal = [];

            datas.forEach((data) => {
              labels.push(data.category);
              labelTotal.push(data.total);
            });
            this.chart.categoryData.labels = labels;
            this.chart.categoryData.datasets[0].data = labelTotal;
            this.chart.categoryData.loaded = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.fa-whatsapp:before, .fa-phone-square:before{
  color: #1cc88a; 
}

.fa-telegram-plane::before{
  color: #0088cc
}
</style>