<template>
  <div id="wrapper">
    <Sidebar />
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <TopNavigation />

        <div class="container-fluid">
          <router-view />
        </div>
        
      </div>
      <footer class="sticky-footer bg-white">
        <div class="container my-auto">
          <div class="copyright text-center my-auto">
            <span>Copyright &copy; Your Website 2020</span>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import TopNavigation from "@/components/TopNavigation.vue";

export default {
  name: "Home",
  components: {
    Sidebar,
    TopNavigation,
  },
};
</script>
